// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Sd from "common/src/tft-static/Sd.bs.js";
import * as Caml from "rescript/lib/es6/caml.js";
import * as React from "react";
import * as Global from "common/src/Global.bs.js";
import * as TftSet from "common/src/data/TftSet.bs.js";
import * as TcUtils from "./TcUtils.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as TraitImage from "common/src/tft-static/TraitImage.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Translation from "common/src/bindings/Translation.bs.js";
import * as AugmentImage from "common/src/tft-static/AugmentImage.bs.js";
import * as TraitTooltip from "common/src/tft-static/TraitTooltip.bs.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";

function TcTitlei(Props) {
  var smallOpt = Props.small;
  var group = Props.group;
  var small = smallOpt !== undefined ? smallOpt : false;
  var unitsT = Translation.useT(/* Units */1);
  var traitsT = Translation.useT(/* Traits */2);
  var augsT = Translation.useT(/* Augments */4);
  var set = TftSet.use(undefined);
  var match = React.useMemo((function () {
          var comps = group.nonSpatComps.length === 0 ? group.spatComps : group.nonSpatComps;
          if (comps.length === 0) {
            group.comps;
          }
          var coreAug = TcUtils.getCoreAug(group);
          var coreTraits = Belt_Array.map(Belt_SortArray.stableSortBy(Belt_Array.keep(group.traits, (function (param) {
                          return Sd.traitRankUnitCount(param[0], param[1], set) > 2;
                        })), (function (param, param$1) {
                      return Caml.caml_float_compare(Global.f(param$1[2]) * Global.f(Sd.traitRankUnitCount(param$1[0], param$1[1], set)), Global.f(param[2]) * Global.f(Sd.traitRankUnitCount(param[0], param[1], set)));
                    })), (function (param) {
                  return [
                          param[0],
                          param[1]
                        ];
                }));
          var renderTrait = Belt_Array.get(coreTraits, 0);
          var renderCarries_0 = Belt_Array.getExn(group.carryUnits, 0)[0];
          var renderCarries_1 = Belt_Array.getExn(group.carryUnits, 1)[1] * 1.5 > Belt_Array.getExn(group.carryUnits, 0)[1] || Belt_Option.isNone(renderTrait) ? Belt_Array.getExn(group.carryUnits, 1)[0] : undefined;
          var titlePrefix = coreAug !== undefined ? augsT(coreAug) + " " : (
              renderTrait !== undefined ? traitsT(renderTrait[0]) + " " : ""
            );
          var title = titlePrefix + unitsT(renderCarries_0) + Belt_Option.mapWithDefault(renderCarries_1, "", (function (u) {
                  return " & " + unitsT(u);
                }));
          return [
                  coreAug,
                  renderTrait,
                  title
                ];
        }), [
        group,
        unitsT,
        traitsT
      ]);
  var renderTrait = match[1];
  var coreAug = match[0];
  var tmp;
  if (coreAug !== undefined) {
    tmp = React.createElement(AugmentImage.make, {
          augId: coreAug,
          size: small ? 22 : 24
        });
  } else if (renderTrait !== undefined) {
    var trait = renderTrait[0];
    tmp = React.createElement(TraitTooltip.make, {
          trait: trait,
          children: React.createElement(TraitImage.PlainIcon.make, {
                traitId: trait,
                size: small ? 22 : 24
              })
        });
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: "flex items-center"
            }, tmp, React.createElement("div", {
                  className: (
                    small ? "text-lg pl-1" : "text-xl pl-[6px]"
                  ) + " font-montserrat font-semibold"
                }, match[2]));
}

var make = TcTitlei;

export {
  make ,
  
}
/* Sd Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as TraitImage from "common/src/tft-static/TraitImage.bs.js";

function Tc2Headliner(Props) {
  var traitId = Props.traitId;
  return React.createElement("div", {
              className: "relative w-[44px] h-[44px] ml-2"
            }, React.createElement("img", {
                  className: "opacity-70 absolute left-0 top-0",
                  height: "20",
                  src: "https://ap.tft.tools/img/general/headliner_tmp.png?w=20",
                  width: "20"
                }), React.createElement("div", {
                  className: "absolute right-0 bottom-0"
                }, React.createElement(TraitImage.PlainIcon.make, {
                      traitId: traitId,
                      size: 34
                    })));
}

var make = Tc2Headliner;

export {
  make ,
  
}
/* react Not a pure module */

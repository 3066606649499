// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Sd from "../tft-static/Sd.bs.js";
import * as Caml from "rescript/lib/es6/caml.js";
import * as Config from "../Config.bs.js";
import * as Trait9 from "../data/Trait9.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Translation2 from "../bindings/Translation2.bs.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";

function extractCore(comps) {
  return Belt_SortArray.stableSortBy(Belt_Array.keep(Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.getExn(comps, 0))), (function (u) {
                    return Belt_Array.every(comps, (function (c) {
                                  return c.includes(u);
                                }));
                  })), (function (u1, u2) {
                return Caml.caml_int_compare(Sd.unitTier(u1), Sd.unitTier(u2));
              }));
}

function removeCoreUnits(units, coreUnits) {
  var remainingUnits = {
    contents: units.slice(0)
  };
  Belt_Array.forEach(coreUnits, (function (u) {
          var idx = remainingUnits.contents.indexOf(u);
          remainingUnits.contents = Belt_Array.concat(Belt_Array.slice(remainingUnits.contents, 0, idx), Belt_Array.sliceToEnd(remainingUnits.contents, idx + 1 | 0));
          
        }));
  return remainingUnits.contents;
}

function getTraits(spatItemsOpt, extraTraitsOpt, unitIds, set) {
  var spatItems = spatItemsOpt !== undefined ? spatItemsOpt : [];
  var extraTraits = extraTraitsOpt !== undefined ? extraTraitsOpt : [];
  var unitIds$1 = Belt_SetString.toArray(Belt_SetString.fromArray(unitIds));
  var allTraits = Belt_Array.concat(Belt_Array.concatMany(Belt_Array.map(unitIds$1, (function (u) {
                  return Sd.getUnitTraitsFull(u, set);
                }))), extraTraits);
  Belt_Array.forEach(spatItems, (function (itemId) {
          var trait = Sd.emblemTrait(itemId, set);
          if (trait !== undefined) {
            allTraits.push(trait);
            return ;
          }
          
        }));
  return Belt_SortArray.stableSortBy(Belt_Array.keepMap(Belt_Array.keep(Sd.allTraits(set), (function (t) {
                        return allTraits.includes(t);
                      })), (function (t) {
                    var count = Belt_Array.keep(allTraits, (function (t2) {
                            return t2 === t;
                          })).length;
                    var level = Sd.traitRank(t, count, set);
                    if (level === 0) {
                      return ;
                    } else {
                      return [
                              t,
                              level
                            ];
                    }
                  })), (function (param, param$1) {
                var l2 = param$1[1];
                var t2 = param$1[0];
                var l1 = param[1];
                var t1 = param[0];
                var c = -Caml.caml_int_compare(Sd.traitRankUnitCount(t1, l1, set), Sd.traitRankUnitCount(t2, l2, set)) | 0;
                if (c !== 0) {
                  return c;
                } else {
                  return -Caml.caml_int_compare(Sd.traitStyle(t1, l1, set), Sd.traitStyle(t2, l2, set)) | 0;
                }
              }));
}

function getCoreTraits(group, set) {
  var traitGroups = Belt_Array.map(group, (function (param) {
          return getTraits(undefined, param[1], param[0], set);
        }));
  return Belt_Array.keepMap(Belt_Array.getExn(traitGroups, 0), (function (trait) {
                return Belt_Array.reduce(Belt_Array.sliceToEnd(traitGroups, 1), trait, (function (trait, traits) {
                              if (trait === undefined) {
                                return ;
                              }
                              var traitId = trait[0];
                              var match = traits.find(function (t) {
                                    return t[0] === traitId;
                                  });
                              if (match !== undefined) {
                                return [
                                        traitId,
                                        Caml.caml_int_min(trait[1], match[1])
                                      ];
                              }
                              
                            }));
              }));
}

function getCoreTraitsStr9(conciseOpt, traits) {
  var concise = conciseOpt !== undefined ? conciseOpt : false;
  return Belt_Array.map(Belt_SortArray.stableSortBy(Belt_Array.keep(traits, (function (param) {
                          var rank = param[1];
                          if (concise && Sd.isTraitUnique(Trait9.toApiName(param[0]), 90)) {
                            return false;
                          } else {
                            return rank > 0;
                          }
                        })), (function (param, param$1) {
                      var t2 = param$1[0];
                      var t1 = param[0];
                      var c1 = Sd.traitRankUnitCount(Trait9.toApiName(t1), param[1], 90);
                      var c2 = Sd.traitRankUnitCount(Trait9.toApiName(t2), param$1[1], 90);
                      if (c1 === c2) {
                        return Caml.caml_string_compare(Translation2.traits(Trait9.toApiName(t1)), Translation2.traits(Trait9.toApiName(t2)));
                      } else {
                        return -Caml.caml_int_compare(c1, c2) | 0;
                      }
                    })), (function (param) {
                  var trait = param[0];
                  return String(Sd.traitRankUnitCount(Trait9.toApiName(trait), param[1], 90)) + " " + Translation2.traits(Trait9.toApiName(trait));
                })).join(", ");
}

function getCoreTraitsStr(conciseOpt, traits, set) {
  var concise = conciseOpt !== undefined ? conciseOpt : false;
  return Belt_Array.map(Belt_SortArray.stableSortBy(Belt_Array.keep(traits, (function (param) {
                          var rank = param[1];
                          if (concise && Sd.isTraitUnique(param[0], set)) {
                            return false;
                          } else {
                            return rank > 0;
                          }
                        })), (function (param, param$1) {
                      var t2 = param$1[0];
                      var t1 = param[0];
                      var c1 = Sd.traitRankUnitCount(t1, param[1], set);
                      var c2 = Sd.traitRankUnitCount(t2, param$1[1], set);
                      if (c1 === c2) {
                        return Caml.caml_string_compare(Translation2.traits(t1), Translation2.traits(t2));
                      } else {
                        return -Caml.caml_int_compare(c1, c2) | 0;
                      }
                    })), (function (param) {
                  var trait = param[0];
                  return String(Sd.traitRankUnitCount(trait, param[1], set)) + " " + Translation2.traits(trait);
                })).join(", ");
}

var set = Config.statsSet;

export {
  set ,
  extractCore ,
  removeCoreUnits ,
  getTraits ,
  getCoreTraits ,
  getCoreTraitsStr9 ,
  getCoreTraitsStr ,
  
}
/* Sd Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Translation from "../bindings/Translation.bs.js";

function ItemName(Props) {
  var itemId = Props.itemId;
  var t = Translation.useT(/* Items */3);
  return t(itemId);
}

var make = ItemName;

export {
  make ,
  
}
/* Translation Not a pure module */

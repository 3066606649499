// Generated by ReScript, PLEASE EDIT WITH CARE

import * as T from "common/src/T.bs.js";
import * as Sd from "common/src/tft-static/Sd.bs.js";
import * as Caml from "rescript/lib/es6/caml.js";
import * as Data from "common/src/data/Data.bs.js";
import * as React from "react";
import * as Global from "common/src/Global.bs.js";
import * as TftSet from "common/src/data/TftSet.bs.js";
import * as Tooltip from "common/src/components/Tooltip.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Translation2 from "common/src/bindings/Translation2.bs.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as Belt_MutableMapInt from "rescript/lib/es6/belt_MutableMapInt.js";

function renderTag(tag) {
  if (typeof tag === "number") {
    switch (tag) {
      case /* Top4 */0 :
          return Translation2.common("Consistent");
      case /* Top1 */1 :
          return Translation2.common("High Win %");
      case /* AugmentDependent */2 :
          return Translation2.common("Augments Dependent");
      case /* EmblemDependent */3 :
          return Translation2.common("Emblems Dependent");
      case /* ItemDependent */4 :
          return Translation2.common("Items Dependent");
      
    }
  } else {
    switch (tag._0) {
      case /* Lvl5 */0 :
          return Translation2.common("Level 5 Reroll");
      case /* Lvl6 */1 :
          return Translation2.common("Level 6 Reroll");
      case /* Lvl7 */2 :
          return Translation2.common("Level 7 Reroll");
      case /* Lvl8 */3 :
          return Translation2.common("Fast Level 8");
      case /* Lvl9 */4 :
          return Translation2.common("Fast Level 8+");
      
    }
  }
}

function tooltipText(tag) {
  if (typeof tag === "number") {
    switch (tag) {
      case /* Top4 */0 :
          return "This team composition tends to provide consistent results. Good choice if you like a consistent playstyle aiming for top-4 placements.";
      case /* Top1 */1 :
          return "This team composition is high-risk, high-reward and can often can win games if things go well, but can often get bottom placements if they don't. Good choice if you like playing for 1st places.";
      case /* AugmentDependent */2 :
          return "This team composition is highly reliant dependent on having certain augments. Try to go for this team composition when you are offered augments that fit it well.";
      case /* EmblemDependent */3 :
          return "This team composition relies on having the right emblems for success. Try to go for it when you are able to get the emblems that it needs.";
      case /* ItemDependent */4 :
          return "This team composition is highly reliant on having optimal items for success. Aim for it when you either have right components at the start of the game or you have augments or regions that help you get them.";
      
    }
  } else {
    switch (tag._0) {
      case /* Lvl5 */0 :
          return "This composition is centered around 3* 1-cost units. To maximize your chances of hitting 3* 1-cost units, stay at level 5 and slow roll. Slow rolling is to roll only the gold you have above 50g to maximize interest. Roll below 50g when you are close to hitting key units. Consider rerolling at level 4 on 3-1 for early 3* if you hit a lot of your key units early.";
      case /* Lvl6 */1 :
          return "This composition is centered around 3* 2-cost units. To maximize your chances of hitting 3* 2-cost units, stay at level 6 and slow roll. Slow rolling is to roll only the gold you have above 50g to maximize interest. Roll below 50g when you are close to hitting key units.";
      case /* Lvl7 */2 :
          return "This composition is centered around 3* 3-cost units. To maximize your chances of hitting 3* 3-cost units, stay at level 7 and slow roll. Slow rolling is to roll only the gold you have above 50g to maximize interest. Roll below 50g when you are close to hitting key units. Consider going level 8 and rolling there if you're winstreaking or if others are also rerolling for your key units and you can't rely on hitting them by staying at level 7.";
      case /* Lvl8 */3 :
          return "This composition is centered around 4-cost units. Aim to either go level 8 on Stage 4-2 or Stage 4-5 as fast as possible or go for a fast level 7 on Stage 3-5 or Stage 4-1. Spend some gold for a more stable board then go for level 8 at Stage 4-5 or Stage 5-1.";
      case /* Lvl9 */4 :
          return "This composition is reliant on fast leveling to get to high tier units and it needs 5-cost units to win the game. You want to go to level 8 as fast as possible ideally at Stage 4-2 or Stage 4-5 and look to go level 9 if your economy allows it. If you can't go level 9, you likely need to preserve HP and play for top 4.";
      
    }
  }
}

function icon(tag) {
  if (tag === 3) {
    return React.createElement("img", {
                height: "18",
                src: "https://cdn.tft.tools/general/spatula.png?width=18",
                width: "18"
              });
  } else {
    return null;
  }
}

function getAugDelta(augs) {
  var best = Belt_Array.slice(augs, 0, 6);
  var other = Belt_Array.slice(augs, 6, 24);
  var bestPl = Belt_Array.reduce(best, 0, (function (acc, param) {
          return acc + param[1];
        })) / Global.f(best.length);
  var otherPl = Belt_Array.reduce(other, 0, (function (acc, param) {
          return acc + param[1];
        })) / Global.f(other.length);
  return otherPl - bestPl;
}

function Tc2Tags(Props) {
  var group = Props.group;
  var compactOpt = Props.compact;
  var compact = compactOpt !== undefined ? compactOpt : false;
  var set = TftSet.use(undefined);
  var match = Belt_Option.getWithDefault(Caml_option.undefined_to_opt(group.levels.find(function (param) {
                return param[0] === 7;
              })), [
        7,
        0,
        0
      ]);
  var lv7TotPlace = match[2];
  var lv7Count = match[1];
  var lv7Place = Global.f(lv7TotPlace) / Global.f(lv7Count);
  var match$1 = Belt_Option.getWithDefault(Caml_option.undefined_to_opt(group.levels.find(function (param) {
                return param[0] === 8;
              })), [
        8,
        0,
        0
      ]);
  var lv8TotPlace = match$1[2];
  var lv8Count = match$1[1];
  var lv8Place = Global.f(lv8TotPlace) / Global.f(lv8Count);
  var match$2 = Belt_Option.getWithDefault(Caml_option.undefined_to_opt(group.levels.find(function (param) {
                return param[0] === 9;
              })), [
        9,
        0,
        0
      ]);
  var lv9TotPlace = match$2[2];
  var lv9Count = match$2[1];
  var lv9Place = Global.f(lv9TotPlace) / Global.f(lv9Count);
  var totCount = (lv7Count + lv8Count | 0) + lv9Count | 0;
  var avg = (Global.f(lv7TotPlace) + Global.f(lv8TotPlace) + Global.f(lv9TotPlace)) / Global.f(totCount);
  var stars = Belt_MutableMapInt.make(undefined);
  var nonStars = Belt_MutableMapInt.make(undefined);
  Belt_Array.forEach(group.units, (function (param) {
          var count = param[2];
          var tier = Sd.unitTier(param[0]);
          if (param[1] >= 3) {
            return Belt_MutableMapInt.update(stars, tier, (function (v) {
                          return Belt_Option.getWithDefault(v, 0) + count | 0;
                        }));
          } else {
            return Belt_MutableMapInt.update(nonStars, tier, (function (v) {
                          return Belt_Option.getWithDefault(v, 0) + count | 0;
                        }));
          }
        }));
  var t1s = Global.f(Belt_MutableMapInt.getWithDefault(stars, 1, 0));
  Global.f(Belt_MutableMapInt.getWithDefault(nonStars, 1, 0));
  var t2s = Global.f(Belt_MutableMapInt.getWithDefault(stars, 2, 0));
  Global.f(Belt_MutableMapInt.getWithDefault(nonStars, 2, 0));
  var t3s = Global.f(Belt_MutableMapInt.getWithDefault(stars, 3, 0));
  Global.f(Belt_MutableMapInt.getWithDefault(nonStars, 3, 0));
  var t4s = Global.f(Belt_MutableMapInt.getWithDefault(stars, 4, 0));
  var t4n = Global.f(Belt_MutableMapInt.getWithDefault(nonStars, 4, 0));
  var t5s = Global.f(Belt_MutableMapInt.getWithDefault(stars, 5, 0));
  var t5n = Global.f(Belt_MutableMapInt.getWithDefault(nonStars, 5, 0));
  var t1 = t1s / Global.f(totCount);
  var t2 = t2s / Global.f(totCount);
  var t3 = t3s / Global.f(totCount) * 2;
  (t4s + t4n) / Global.f(totCount);
  (t5s + t5n) / Global.f(totCount);
  (lv7Place - avg) / (1 - Global.f(lv7Count) / Global.f(totCount));
  var lv8d = (lv8Place - avg) / (1 - Global.f(lv8Count) / Global.f(totCount));
  var lv9d = (lv9Place - avg) / (1 - Global.f(lv9Count) / Global.f(totCount));
  var label = t2 >= 0.75 || t1 >= 1 ? (
      t2 > t1 ? /* Level */({
            _0: /* Lvl6 */1
          }) : /* Level */({
            _0: /* Lvl5 */0
          })
    ) : (
      t3 >= 1 ? /* Level */({
            _0: /* Lvl7 */2
          }) : (
          lv9d - lv8d < -5 ? /* Level */({
                _0: /* Lvl9 */4
              }) : /* Level */({
                _0: /* Lvl8 */3
              })
        )
    );
  var tags = [label];
  var top4 = Global.f(group.top4) / Global.f(group.count);
  var top1 = Global.f(group.win) / Global.f(group.count);
  var emblemUse = Global.f(Belt_Array.reduce(Belt_Array.keep(group.generalItems, (function (param) {
                  var info = Data.itemInfoExn(param[0], set);
                  if (info.tags.includes("CraftableEmblem")) {
                    return true;
                  } else {
                    return info.tags.includes("ElusiveEmblem");
                  }
                })), 0, (function (acc, param) {
              return acc + param[1] | 0;
            }))) / Global.f(group.count);
  var emblemDelta = group.lpDeltaSingleSpat - group.lpDeltaNoSpat;
  var emblemDependency = emblemDelta * emblemUse;
  if (emblemDependency > 2.5) {
    tags.push(/* EmblemDependent */3);
  }
  var totItemDelta = -1 * Belt_Array.reduce(Belt_Array.slice(Belt_SortArray.stableSortBy(Belt_Array.map(Belt_Array.keep(group.itemDeltas, (function (param) {
                          var info = Data.itemInfo(param[1], set);
                          if (info !== undefined) {
                            return Caml_option.valFromOption(info).id < 45;
                          } else {
                            return false;
                          }
                        })), (function (param) {
                      return param[2];
                    })), Caml.caml_float_compare), 0, 10), 0, (function (acc, v) {
          return acc + v;
        }));
  if (totItemDelta > 2.7) {
    tags.push(/* ItemDependent */4);
  }
  var augmentSingles = group.augmentSingles;
  var augmentSingles$1 = Belt_Array.map(Belt_SortArray.stableSortBy(Belt_Array.map(Belt_Array.keep(augmentSingles, (function (param) {
                      return Belt_Option.isSome(Data.augmentInfo(param[0], set));
                    })), (function (param) {
                  var pl = param[2];
                  return [
                          param[0],
                          pl,
                          T.calcSafePlace(4, pl, param[1])
                        ];
                })), (function (param, param$1) {
              return Caml.caml_float_compare(param[2], param$1[2]);
            })), (function (param) {
          return [
                  param[0],
                  param[1]
                ];
        }));
  var silvers = Belt_Array.keep(augmentSingles$1, (function (param) {
          return Data.augmentInfoExn(param[0], set).rank === 1;
        }));
  Belt_Array.keep(augmentSingles$1, (function (param) {
          return Data.augmentInfoExn(param[0], set).rank === 2;
        }));
  var prisms = Belt_Array.keep(augmentSingles$1, (function (param) {
          return Data.augmentInfoExn(param[0], set).rank === 3;
        }));
  var silverDelta = getAugDelta(silvers);
  var goldDelta = getAugDelta(silvers);
  var prismDelta = getAugDelta(prisms);
  var augmentDependency = silverDelta * 1 + goldDelta * 3 + prismDelta;
  if (augmentDependency > 1.5) {
    tags.push(/* AugmentDependent */2);
  }
  if (top1 >= 0.15) {
    tags.push(/* Top1 */1);
  } else if (top4 >= 0.55) {
    tags.push(/* Top4 */0);
  }
  return React.createElement("div", {
              className: "flex flex-col gap-2 text-sm font-montserrat font-medium w-[130px]"
            }, Belt_Array.mapWithIndex(Belt_Array.slice(tags, 0, compact ? 2 : 3), (function (idx, tag) {
                    return React.createElement(Tooltip.make, {
                                title: React.createElement("div", {
                                      className: "leading-snug"
                                    }, tooltipText(tag)),
                                children: React.createElement("div", {
                                      className: "bg-bg2 pl-[7px] pr-[6px] py-[5px] rounded-md truncate flex items-center border-primary-10 border-b border-t"
                                    }, React.createElement("div", {
                                          className: "truncate"
                                        }, renderTag(tag))),
                                key: String(idx)
                              });
                  })));
}

var make = React.memo(Tc2Tags);

export {
  renderTag ,
  tooltipText ,
  icon ,
  getAugDelta ,
  make ,
  
}
/* make Not a pure module */
